import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import PageContainer from "../../components/container/PageContainer";
import img1 from "../../assets/images/backgrounds/home_bg.jpg";
import LogoIcon from "../../layouts/full-layout/logo/LogoIcon";
import { Button } from "@mui/material";
import { useNavigate } from "react-router";

export default function Privacy() {
  const navigate = useNavigate();
  return (
    <PageContainer
      title="Privacy Policy"
      description="this is Privacy policy page"
    >
      <Grid
        container
        spacing={0}
        sx={{
          // backgroundImage: `url(${img1})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          display: "flex",
          backgroundPosition: "center",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Grid
        // sx={{
        //   background: "rgba( 255, 255, 255, 0.45 )",
        //   boxShadow: "0 8px 32px 0 rgba( 31, 38, 135, 0.37 )",
        //   backdropFilter: "blur( 10px )",
        //   height: "90%",
        //   width: "90%",
        //   justifyContent: "center",
        //   alignItems: "center",
        //   borderRadius: "10px",
        //   marginY: "5%",
        // }}
        >
          <Box
            sx={{
              margin: "2%",
            }}
          >
            <Typography
              variant="h1"
              sx={{ textAlign: "center", mb: 5, color: "#441F00" }}
            >
              Privacy Policy
            </Typography>

            <Typography variant="h6" sx={{ textAlign: "center" }}>
              1. GENERAL
            </Typography>
            <Typography variant="p" sx={{ textAlign: "left", display: "block", mb: 2 }}>
              a) Introduction: We at Inara Skin [Website/App], a company owned by Inara Derma Solutions Private Limited, are committed to transparently outlining how we handle users' personal information. This Privacy Policy informs users about the collection, usage, and disclosure of their information through our website or application ("Service"). Please note that this document is electronically generated and does not require physical or digital signatures.
            </Typography>
            <Typography variant="p" sx={{ textAlign: "left", display: "block", mb: 2 }}>
              b) Personal Information Protection: At Inara Skin [Website/App], we prioritize the protection of your Personal Information, such as name, address, phone number, date of birth, email address, gender, and language preference. We collect this information voluntarily provided by you and process it for the following purposes:
            </Typography>
            <Typography variant="p" sx={{ textAlign: "left", display: "block", ml: 2, mb: 1 }}>
              i) To take and fulfill orders and deliver products in our catalog to you.
            </Typography>
            <Typography variant="p" sx={{ textAlign: "left", display: "block", ml: 2, mb: 1 }}>
              ii) To directly market to you, with your permission.
            </Typography>
            <Typography variant="p" sx={{ textAlign: "left", display: "block", ml: 2, mb: 1 }}>
              iii) To understand and analyze our sales, and your needs and preferences.
            </Typography>
            <Typography variant="p" sx={{ textAlign: "left", display: "block", ml: 2, mb: 1 }}>
              iv) To develop, enhance, market, and provide products and services to meet your needs.
            </Typography>
            <Typography variant="p" sx={{ textAlign: "left", display: "block", ml: 2, mb: 1 }}>
              v) To enable you to participate in promotions and contests.
            </Typography>
            <Typography variant="p" sx={{ textAlign: "left", display: "block", ml: 2, mb: 1 }}>
              vi) To enable you to participate in customer research or focus groups.
            </Typography>
            <Typography variant="p" sx={{ textAlign: "left", display: "block", ml: 2, mb: 1 }}>
              vii) To comply with the law.
            </Typography>
            <Typography variant="p" sx={{ textAlign: "left", display: "block", ml: 2, mb: 1 }}>
              viii) To respond to requests or complaints.
            </Typography>
            <Typography variant="p" sx={{ textAlign: "left", display: "block", ml: 2, mb: 2 }}>
              ix) To enable you to participate in our customer loyalty schemes and manage your membership.
            </Typography>
            <Typography variant="p" sx={{ textAlign: "left", display: "block", mb: 2 }}>
              c) Compliance with Legal Requirements: This document is published in accordance with Rule 3 (1) of the Information Technology (Intermediaries Guidelines) Rules, 2011, which require publishing the Rules and Regulations, Privacy Policy, and Terms of Use for accessing or using Inara Skin [Website/App].
            </Typography>
            <Typography variant="p" sx={{ textAlign: "left", display: "block", mb: 2 }}>
              d) Ownership and Operation: The domain name Inara Skin ("Mobile Application") is owned and operated by Inara Derma Solutions Private Limited., a Private Company limited by shares, incorporated under the Companies Act, 2013, with its registered office at 3rd Floor, Wing A, Enkay Centre, Vaniya Kunj, Udyog Vihar, Phase V, Udyog Vihar, Sector 19, Gurugram, Haryana – 122016. This expression includes its representatives, administrators, employees, officers, and their assigns.
            </Typography>

            <Typography variant="h6" sx={{ textAlign: "center" }}>
              2. APPROACH TO PRIVACY
            </Typography>
            <Typography variant="p" sx={{ textAlign: "left", display: "block", mb: 2 }}>
              a) Importance of Privacy: At Inara Skin [Website/App], we recognize that protecting your Personal Information is crucial for building trust and maintaining a good relationship with you.
            </Typography>
            <Typography variant="p" sx={{ textAlign: "left", display: "block", mb: 2 }}>
              b) Definitions:
            </Typography>
            <Typography variant="p" sx={{ textAlign: "left", display: "block", ml: 2, mb: 1 }}>
              i) "You" and "User" refer to any legal person or entity accessing or availing the services provided on this Mobile application, who is competent to enter into binding contracts as per the Indian Contract Act, 1872.
            </Typography>
            <Typography variant="p" sx={{ textAlign: "left", display: "block", ml: 2, mb: 1 }}>
              ii) "We," "Us," and "Our" refer to the Application, Mobile application, or the Company, i.e., Inara Derma Solutions Private Limited., or any of its subsidiaries connected to Tagbin Services Pvt. Ltd.
            </Typography>
            <Typography variant="p" sx={{ textAlign: "left", display: "block", ml: 2, mb: 2 }}>
              iii) "Party" and "Parties" refer to the User and the Company individually and collectively, as the context requires.
            </Typography>
            <Typography variant="p" sx={{ textAlign: "left", display: "block", mb: 2 }}>
              c) Privacy Policy Commitments: This Privacy Policy details our commitments and your rights regarding the processing of your Personal Information in line with applicable legislation.
            </Typography>
            <Typography variant="p" sx={{ textAlign: "left", display: "block", mb: 2 }}>
              d) Section Headings: The headings in this Policy are for organizing the provisions and shall not be used to interpret the provisions contained herein. They have no legal or contractual value.
            </Typography>
            <Typography variant="p" sx={{ textAlign: "left", display: "block", mb: 2 }}>
              e) Governing Policies: The use of the Mobile application by the User is governed by this Policy, the Terms of Use of the Mobile application ("Terms of Use," available at Inara Skin [Website/App]), and any modifications or amendments made by the Company. Visiting the home page of the Mobile application and/or using any of the services provided signifies the User’s acceptance of this Policy and the Terms.
            </Typography>
            <Typography variant="p" sx={{ textAlign: "left", display: "block", mb: 2 }}>
              f) Binding Agreement: The User agrees that this Policy and the Terms constitute a legally binding agreement between the User and the Company. The User acknowledges that no signature is required to make these Terms and the Policy binding, and visiting any part of the Mobile application constitutes the User’s full acceptance of the Policy and the Terms.
            </Typography>
            <Typography variant="p" sx={{ textAlign: "left", display: "block", mb: 2 }}>
              g) Amendments and Modifications: The Company retains the right to amend or modify the Policy and Terms without prior permission or intimation to the User. Such amendments or modifications shall take effect immediately.
            </Typography>
            <Typography variant="p" sx={{ textAlign: "left", display: "block", mb: 2 }}>
              h) Continued Use: If the User continues to use the Mobile application following any changes, they will be deemed to have consented to all amendments/modifications. As long as the User complies with the Policy and Terms, they are granted a personal, non-exclusive, non-transferable, revocable, limited privilege to enter, access, and use the Mobile application.
            </Typography>
            <Typography variant="h6" sx={{ textAlign: "center" }}>
              3. COLLECTION OF PERSONAL AND OTHER INFORMATION
            </Typography>
            <Typography variant="p" sx={{ textAlign: "left", display: "block", mb: 2 }}>
              a) Limited and Purposeful Collection: At Inara Skin [Website/App], we limit the amount and type of information collected to what is necessary for the identified purposes. We do not collect your Personal Information unless you provide it voluntarily, directly or indirectly.
            </Typography>
            <Typography variant="p" sx={{ textAlign: "left", display: "block", mb: 2 }}>
              b) Device Information: We collect information about your device to ensure seamless usage of our Services, troubleshoot technical issues, and prevent or resolve any errors that may arise during your interaction with our platform.
            </Typography>
            <Typography variant="p" sx={{ textAlign: "left", display: "block", mb: 2 }}>
              c) Types of Personal Information Collected: We collect specific types of personal information tailored to meet the purposes of our services, which may include:
            </Typography>
            <Typography variant="p" sx={{ textAlign: "left", display: "block", ml: 2, mb: 1 }}>
              i) Name, Age, Sex, Skin Characteristics, Inferences About Your Skin: Collected to provide a safe and personalized experience with our chat support service.
            </Typography>
            <Typography variant="p" sx={{ textAlign: "left", display: "block", ml: 2, mb: 1 }}>
              ii) Name and Email Address: Used to manage your account and provide responsive customer support.
            </Typography>
            <Typography variant="p" sx={{ textAlign: "left", display: "block", ml: 2, mb: 1 }}>
              iii) Age and Gender: Collected to customize your skincare plan, ensuring a safe and tailored experience with Inara Skin.
            </Typography>
            <Typography variant="p" sx={{ textAlign: "left", display: "block", ml: 2, mb: 1 }}>
              iv) Application Usage Data: We monitor your interactions with our app’s features to enhance functionality and build data analytics, focusing on how our services can better suit your needs.
            </Typography>
            <Typography variant="p" sx={{ textAlign: "left", display: "block", ml: 2, mb: 1 }}>
              v) Chat Data: Used to provide effective chat support. We anonymize data associated with your photos for analytics, ensuring data privacy while enhancing service quality.
            </Typography>
            <Typography variant="p" sx={{ textAlign: "left", display: "block", ml: 2, mb: 1 }}>
              vi) Face Data Collection and Use: We collect facial images to personalize your skincare programs through AI-based visual analysis of skin conditions, such as pimples, wrinkles, pigmentation, etc. Photos may be taken using the in-app or web-based camera, always with your active involvement.
            </Typography>
            <Typography variant="p" sx={{ textAlign: "left", display: "block", ml: 4, mb: 1 }}>
              Types of images collected include:
            </Typography>
            <Typography variant="p" sx={{ textAlign: "left", display: "block", ml: 4, mb: 1 }}>
              ● Close-up front face image
            </Typography>
            <Typography variant="p" sx={{ textAlign: "left", display: "block", ml: 4, mb: 1 }}>
              ● Close-up left-side face image
            </Typography>
            <Typography variant="p" sx={{ textAlign: "left", display: "block", ml: 4, mb: 1 }}>
              ● Close-up right-side face image
            </Typography>
            <Typography variant="p" sx={{ textAlign: "left", display: "block", ml: 4, mb: 1 }}>
              ● Skin type (e.g., oily, dry, combination, sensitive, sensitive oily skin, sensitive dry skin)
            </Typography>
            <Typography variant="p" sx={{ textAlign: "left", display: "block", ml: 4, mb: 1 }}>
              ● Skin issues and characteristics (e.g., blemishes, pores, acne, dark circles, fine lines, wrinkles, uneven skin tone) may also be recorded to enhance the personalization of your skincare regimen.
            </Typography>
            <Typography variant="p" sx={{ textAlign: "left", display: "block", mb: 2 }}>
              vii) Customization of Services Based on Collected Data: The data collected enables us to adjust the content and features of our services, offering you a customized experience tailored to your preferences and skincare needs.
            </Typography>
            <Typography variant="p" sx={{ textAlign: "left", display: "block", mb: 2 }}>
              viii) Disclosure and Sharing of Face Data: We prioritize your privacy. Face Data is strictly for internal analysis purposes and is not shared with any third parties. Automated processes have limited access to Face Data solely for the purpose of analysis, ensuring data security and confidentiality.
            </Typography>
            <Typography variant="p" sx={{ textAlign: "left", display: "block", mb: 2 }}>
              ix) Retention of Face Data: With your explicit consent, we may retain and use your Face Data for up to three years to enhance Inara Skin’s offerings and provide improved services. Consent can be withdrawn at any time by contacting us at customersupport@inaraskinapp.com. Upon receiving your request, we will promptly delete your Face Data from our records. Rest assured, we do not share your Face Data with third parties, and all processing occurs solely within Inara Skin’s secure system.
            </Typography>
            <Typography variant="p" sx={{ textAlign: "left", display: "block", mb: 2 }}>
              d) Specific Features and Activities: We may ask you to submit personal information to benefit from certain features (e.g., newsletter subscriptions, tips/pointers, order processing) or participate in activities (e.g., sweepstakes, promotions). You will be informed of what information is required and what is optional.
            </Typography>
            <Typography variant="p" sx={{ textAlign: "left", display: "block", mb: 2 }}>
              e) Combining Information: We may combine the information you submit with other information we have collected from you, both online and offline, including your purchase history. We may also combine it with information from other sources, such as other Inara Derma Solutions Private Limited Affiliates, publicly available information sources (including your social media profiles), and other third-party information providers.
            </Typography>
            <Typography variant="p" sx={{ textAlign: "left", display: "block", mb: 2 }}>
              f) Information on Others: If you submit any personal information relating to another individual, you represent that you have the authority to do so and to permit us to use the information in accordance with this Privacy Policy.
            </Typography>
            <Typography variant="p" sx={{ textAlign: "left", display: "block", mb: 2 }}>
              g) Use by Minors: The Service is not directed to individuals under the age of 18, and we request that they do not provide personal information through the Service. If a child has submitted Personal Information and you would like it removed, please contact us as explained below under "Contacting Us." The child should provide the details under the supervision of a guardian.
            </Typography>
            <Typography variant="p" sx={{ textAlign: "left", display: "block", mb: 2 }}>
              h) Sensitive Information: Unless specifically requested, we ask that you not send or disclose any sensitive personal information (e.g., Social Security numbers, information related to racial or ethnic origin, political opinions, religion or philosophical beliefs, health, sex life or sexual orientation, criminal background, trade union membership, biometric or genetic data for uniquely identifying an individual) on or through the Service.
            </Typography>

            <Typography variant="h6" sx={{ textAlign: "center" }}>
              4. AUTOMATIC INFORMATION COLLECTION AND USE
            </Typography>
            <Typography variant="p" sx={{ textAlign: "left", display: "block", mb: 2 }}>
              We and our service providers may automatically collect and use information as you navigate around the Service in the following ways:
            </Typography>
            <Typography variant="p" sx={{ textAlign: "left", display: "block", mb: 2 }}>
              a) Through Your Browser: We collect certain information from most browsers, such as your Media Access Control (MAC) address, computer type (Windows or Mac), screen resolution, operating system name and version, and Internet browser type and version. We may collect similar information if you access the Service through a mobile device. This information ensures the proper functioning of the Service.
            </Typography>
            <Typography variant="p" sx={{ textAlign: "left", display: "block", mb: 2 }}>
              b) Using Cookies: Cookies are pieces of information stored directly on your computer. They allow us to collect information such as browser type, time spent on the Service, pages visited, and language preferences. We use this information for security purposes, to facilitate navigation, display information more effectively, and personalize your experience. Cookies also help us recognize your computer or device, remember what is in your shopping cart, gather statistical information, and understand how individuals use the Service. You can refuse cookies by following your browser’s instructions, but this may cause some inconvenience in using the Service.
            </Typography>
            <Typography variant="p" sx={{ textAlign: "left", display: "block", mb: 2 }}>
              c) Using Pixel Tags, Web Beacons, Clear GIFs, or Similar Technologies: These technologies may be used on some Service pages and HTML-formatted email messages to track user actions, measure the success of our marketing campaigns, and compile statistics about Service usage.
            </Typography>
            <Typography variant="p" sx={{ textAlign: "left", display: "block", mb: 2 }}>
              d) Interest-Based Advertising: We may use third-party advertising companies to serve ads that may interest you when you use the Service and other online services. These companies may place or recognize a unique cookie on your browser and use information about your online activity to recognize you across devices.
            </Typography>
            <Typography variant="p" sx={{ textAlign: "left", display: "block", mb: 2 }}>
              e) IP Address: Your IP address is automatically assigned to your computer by your Internet Service Provider. We log IP addresses automatically in our server log files when users visit the Service. This practice helps us calculate Service usage levels, diagnose server problems, and administer the Service. We may also derive your approximate location from your IP address.
            </Typography>
            <Typography variant="p" sx={{ textAlign: "left", display: "block", mb: 2 }}>
              f) Device Information: We may collect information about your mobile device, such as a unique device identifier, to understand how you use the Service.
            </Typography>

            <Typography variant="h6" sx={{ textAlign: "center" }}>
              5. HOW WE USE YOUR PERSONAL DATA
            </Typography>
            <Typography variant="p" sx={{ textAlign: "left", display: "block", mb: 2 }}>
              We use and disclose the information you provide to us as described at the point of collection. Where required by applicable law, we will obtain your consent to use your personal information at the point of collection. We may also use your information to perform a contract, comply with a legal obligation, or for our legitimate business interests. Specifically, we use your information for the following purposes:
            </Typography>
            <Typography variant="p" sx={{ textAlign: "left", display: "block", mb: 2 }}>
              a) Providing the Functionality of the Service and Fulfilling Your Requests:
            </Typography>
            <Typography variant="p" sx={{ textAlign: "left", display: "block", ml: 2, mb: 1 }}>
              ● To offer the functionality of the Service and provide related customer support.
            </Typography>
            <Typography variant="p" sx={{ textAlign: "left", display: "block", ml: 2, mb: 1 }}>
              ● To respond to your inquiries and fulfill your requests, such as sending you requested documents or email alerts.
            </Typography>
            <Typography variant="p" sx={{ textAlign: "left", display: "block", mb: 2 }}>
              b) Accomplishing Our Business Purposes:
            </Typography>
            <Typography variant="p" sx={{ textAlign: "left", display: "block", ml: 2, mb: 1 }}>
              ● For data analysis to improve the efficiency of the Service.
            </Typography>
            <Typography variant="p" sx={{ textAlign: "left", display: "block", ml: 2, mb: 1 }}>
              ● For audits to verify our internal processes are functioning as intended and comply with legal, regulatory, or contractual requirements.
            </Typography>
            <Typography variant="p" sx={{ textAlign: "left", display: "block", ml: 2, mb: 1 }}>
              ● For fraud and security monitoring, such as detecting and preventing cyber attacks or identity theft attempts.
            </Typography>
            <Typography variant="p" sx={{ textAlign: "left", display: "block", ml: 2, mb: 1 }}>
              ● For developing new products and services.
            </Typography>
            <Typography variant="p" sx={{ textAlign: "left", display: "block", ml: 2, mb: 1 }}>
              ● For enhancing, improving, or modifying our website or products and services.
            </Typography>
            <Typography variant="p" sx={{ textAlign: "left", display: "block", ml: 2, mb: 1 }}>
              ● For identifying Service usage trends to understand which parts of our Service are most interesting to users.
            </Typography>
            <Typography variant="p" sx={{ textAlign: "left", display: "block", ml: 2, mb: 1 }}>
              ● For determining the effectiveness of our promotional campaigns to adapt our campaigns to user needs and interests.
            </Typography>
            <Typography variant="p" sx={{ textAlign: "left", display: "block", mb: 2 }}>
              c) Analyzing Personal Information to Provide Personalized Services:
            </Typography>
            <Typography variant="p" sx={{ textAlign: "left", display: "block", ml: 2, mb: 1 }}>
              ● To better understand you and personalize our interactions with you.
            </Typography>
            <Typography variant="p" sx={{ textAlign: "left", display: "block", ml: 2, mb: 1 }}>
              ● To provide you with information and offers tailored to your interests.
            </Typography>
            <Typography variant="p" sx={{ textAlign: "left", display: "block", ml: 2, mb: 1 }}>
              ● To better understand your preferences and deliver relevant and interesting content via the Service.
            </Typography>

            <Typography variant="h6" sx={{ textAlign: "center" }}>
              6. CHOICES AND ACCESS
            </Typography>
            <Typography variant="p" sx={{ textAlign: "left", display: "block", mb: 2 }}>
              Your Choices Regarding Our Use and Disclosure of Your Personal Information:
            </Typography>
            <Typography variant="p" sx={{ textAlign: "left", display: "block", mb: 2 }}>
              a) Receiving Marketing Communications from Us:
            </Typography>
            <Typography variant="p" sx={{ textAlign: "left", display: "block", ml: 2, mb: 1 }}>
              ● If you no longer want to receive marketing communications from us, you can opt out by contacting us via email or updating your online profile on the Service.
            </Typography>
            <Typography variant="p" sx={{ textAlign: "left", display: "block", mb: 2 }}>
              b) Receiving Reminders from Us:
            </Typography>
            <Typography variant="p" sx={{ textAlign: "left", display: "block", ml: 2, mb: 1 }}>
              ● If you no longer want to receive medical reminders, contact us via email.
            </Typography>
            <Typography variant="p" sx={{ textAlign: "left", display: "block", mb: 2 }}>
              c) Sharing Your Personal Information with Affiliates and Third-Party Partners:
            </Typography>
            <Typography variant="p" sx={{ textAlign: "left", display: "block", ml: 2, mb: 1 }}>
              ● If you previously opted in to receive marketing communications from our affiliates or third-party partners, you can opt out by contacting us via email.
            </Typography>

            <Typography variant="h6" sx={{ textAlign: "center" }}>
              7. USER RESPONSIBILITIES
            </Typography>
            <Typography variant="p" sx={{ textAlign: "left", display: "block", mb: 2 }}>
              Users are responsible for providing accurate information about their skin, skin issues, and any other relevant details to ensure the smooth functioning of the application. This includes providing correct contact and personal particulars. The company is not liable for any incorrect information submitted by users on Inara Skin [Website/App] at any time.
            </Typography>

            <Typography variant="h6" sx={{ textAlign: "center" }}>
              8. DIVULGING/SHARING OF PERSONAL INFORMATION
            </Typography>
            <Typography variant="p" sx={{ textAlign: "left", display: "block", mb: 2 }}>
              a) Third-Party Service Providers: We may share your information with third-party service providers who assist us in operating and maintaining the Portal. These providers are obligated to protect your data.
            </Typography>
            <Typography variant="p" sx={{ textAlign: "left", display: "block", mb: 2 }}>
              b) Legal Disclosure: The Company may disclose information if legally required to do so, pursuant to an order from a governmental entity, or in good faith belief that such action is necessary to:
            </Typography>
            <Typography variant="p" sx={{ textAlign: "left", display: "block", ml: 2, mb: 1 }}>
              ● Comply with legal requirements or legal process.
            </Typography>
            <Typography variant="p" sx={{ textAlign: "left", display: "block", ml: 2, mb: 1 }}>
              ● Protect our rights or property or those of our affiliated authorities.
            </Typography>
            <Typography variant="p" sx={{ textAlign: "left", display: "block", ml: 2, mb: 1 }}>
              ● Prevent a crime or protect national security.
            </Typography>
            <Typography variant="p" sx={{ textAlign: "left", display: "block", ml: 2, mb: 1 }}>
              ● Protect the personal safety of users or the public.
            </Typography>
            <Typography variant="p" sx={{ textAlign: "left", display: "block", ml: 2, mb: 1 }}>
              ● Accept or reject any inappropriate information.
            </Typography>
            <Typography variant="h6" sx={{ textAlign: "center" }}>
              9. USER CONSENT
            </Typography>
            <Typography variant="p" sx={{ textAlign: "left", display: "block", mb: 2 }}>
              a) Opt-In: We will not use your Personal Information for any purpose that has not been previously specified unless we have obtained your consent or such use is required by law. Consent to provide Personal Information is not a condition for purchasing a product unless the information requested is necessary to fulfill a legitimate purpose.
            </Typography>
            <Typography variant="p" sx={{ textAlign: "left", display: "block", mb: 2 }}>
              b) Marketing Communications: We will seek your explicit prior consent (opt-in) before sending you marketing communications.
            </Typography>
            <Typography variant="p" sx={{ textAlign: "left", display: "block", mb: 2 }}>
              c) Unsubscribe: You can withdraw your consent to our collection, use, or disclosure (generally processing) of your Personal Information at any time by:
            </Typography>
            <Typography variant="p" sx={{ textAlign: "left", display: "block", ml: 2, mb: 1 }}>
              ● Contacting us via email or phone, as specified in our communications to you.
            </Typography>
            <Typography variant="p" sx={{ textAlign: "left", display: "block", ml: 2, mb: 1 }}>
              ● Writing to us in the prescribed manner, as specified in the relevant forms you might have signed (e.g., for our customer loyalty scheme).
            </Typography>
            <Typography variant="p" sx={{ textAlign: "left", display: "block", mb: 2 }}>
              d) Continued Consent: If you have previously opted into receiving commercial communications from us and are also a member of our loyalty scheme, the end of your membership does not imply an automatic request to unsubscribe. We will assume your continued consent unless you specifically unsubscribe.
            </Typography>

            <Typography variant="h6" sx={{ textAlign: "center" }}>
              10. SECURITY MEASURES
            </Typography>
            <Typography variant="p" sx={{ textAlign: "left", display: "block", mb: 2 }}>
              We take the protection of your Personal Data very seriously and take all reasonable and appropriate measures to protect them from loss, theft, misuse, and unauthorized access, disclosure, alteration, and destruction.
            </Typography>
            <Typography variant="p" sx={{ textAlign: "left", display: "block", mb: 2 }}>
              Please understand that no security system is perfect and, as such, we cannot guarantee the absolute security of the Services, or that your information won’t be intercepted while being transmitted to us. In case your Personal Data got compromised due to a security breach, we will act promptly to identify the cause and take all reasonable steps to remedy the breach. We will inform you of the incident, if necessary, in connection with the applicable legislation.
            </Typography>
            <Typography variant="p" sx={{ textAlign: "left", display: "block", mb: 2 }}>
              If you want to report a security incident related to our Services, please contact us at customersupport@inaraskinapp.com.
            </Typography>

            <Typography variant="h6" sx={{ textAlign: "center" }}>
              11. GRIEVANCE OFFICER
            </Typography>
            <Typography variant="p" sx={{ textAlign: "left", display: "block", mb: 2 }}>
              If you have any grievances related to the use of the mobile application, you may contact our grievance redressal officer. The grievance redressal officer is authorized to address and resolve any user complaints or queries within 30 days of receipt.
            </Typography>
            <Typography variant="p" sx={{ textAlign: "left", display: "block", mb: 2 }}>
              In accordance with the Information Technology Act 2000 and its associated rules, the name and contact details of the Grievance Officer are provided below:
            </Typography>
            <Typography variant="p" sx={{ textAlign: "left", display: "block", ml: 2, mb: 1 }}>
              Name: Dr. Neha Yadav
            </Typography>
            <Typography variant="p" sx={{ textAlign: "left", display: "block", ml: 2, mb: 1 }}>
              Contact No: +91 92668 43867
            </Typography>
            <Typography variant="p" sx={{ textAlign: "left", display: "block", ml: 2, mb: 2 }}>
              Email: customersupport@inaraskinapp.com
            </Typography>

            <Typography variant="h6" sx={{ textAlign: "center" }}>
              12. NOTICES, JURISDICTION, AND GOVERNING LAW FOR DISPUTE RESOLUTION
            </Typography>
            <Typography variant="p" sx={{ textAlign: "left", display: "block", mb: 2 }}>
              a) Notices: Any notices or communications required to be given or served by any party under this Agreement shall be in writing in English. Such notices shall be deemed duly served if sent by prepaid registered mail with acknowledgment due to the address specified in this Agreement or any other address notified by the parties. A notice under this Agreement shall be deemed received:
            </Typography>
            <Typography variant="p" sx={{ textAlign: "left", display: "block", ml: 2, mb: 1 }}>
              ● If delivered personally, at the time of delivery.
            </Typography>
            <Typography variant="p" sx={{ textAlign: "left", display: "block", ml: 2, mb: 1 }}>
              ● If sent by courier, 72 hours from the date of posting.
            </Typography>
            <Typography variant="p" sx={{ textAlign: "left", display: "block", mb: 2 }}>
              b) Jurisdiction and Governing Law: Any disputes or claims arising out of this Agreement are subject to arbitration and the jurisdiction of Gurugram, Haryana Courts. If the parties are unable to resolve the dispute, this Agreement shall be governed by and construed in accordance with the law and jurisdiction of Gurugram, India.
            </Typography>
            <Typography variant="p" sx={{ textAlign: "left", display: "block", mb: 2 }}>
              c) Amendments: Any amendments to the clauses of this Agreement can be made as an addendum, with written approval from both parties.
            </Typography>

            <Typography variant="h6" sx={{ textAlign: "center" }}>
              13. CONTACTING US
            </Typography>
            <Typography variant="p" sx={{ textAlign: "left", display: "block", mb: 2 }}>
              Inara Derma Solutions Private Limited collection, use, and disclosure of personal information under this Privacy Policy.
            </Typography>
            <Typography variant="p" sx={{ textAlign: "left", display: "block", mb: 2 }}>
              If you have any questions about this Privacy Policy, please contact us by sending an email to the e-mail address stated in “Contacting Us” section, or please write to the following address:
            </Typography>
            <Typography variant="p" sx={{ textAlign: "left", display: "block", ml: 2, mb: 2 }}>
              Inara Derma Solutions Private Limited
            </Typography>
            <Typography variant="p" sx={{ textAlign: "left", display: "block", ml: 2, mb: 2 }}>
              Address - 4003,
            </Typography>
            <Typography variant="p" sx={{ textAlign: "left", display: "block", ml: 2, mb: 2 }}>
              1st and 2nd floor,
            </Typography>
            <Typography variant="p" sx={{ textAlign: "left", display: "block", ml: 2, mb: 2 }}>
              DLF Phase 4, Sadar Bazar,
            </Typography>
            <Typography variant="p" sx={{ textAlign: "left", display: "block", ml: 2, mb: 2 }}>
              Gurugram, Haryana - 122001.
            </Typography>

            <Typography variant="h6" sx={{ textAlign: "center" }}>
              14. UPDATES TO THIS PRIVACY POLICY
            </Typography>
            <Typography variant="p" sx={{ textAlign: "left", display: "block", mb: 2 }}>
              We may change this Privacy Policy. Any changes to this Privacy Policy will become effective when we post the revised Privacy Policy on the Service. Your use of the Service following these changes means that you accept the revised Privacy Policy. We recommend that you regularly review the Privacy Policy when you visit the Service.This policy was last
              updated on November 2024.
            </Typography>

          </Box>
        </Grid>
      </Grid>
    </PageContainer>
  );
}
